/* .menu-item:hover {
    background-color: #B22222 !important;
  } */

.menu-item.active {
  background-color: #b22222 !important;
}

.centered-logo {
  text-align: center;
  padding: 20px 0;
}

.table {
  text-align: left;
}

.divider-line {
  width: 1px;
  height: 90%;
  background-color: #000;
  margin: 0 10px;
}

.no-left-margin {
  margin-left: 0;
}

.error {
  color: #ff0000;
}

.image-container-contractor {
  display: flex;
  justify-content: center;
}
.contractor-image {
  border-radius: 11.9px;
  background: #b6b6b6;
  text-align: center;
  padding: 2rem 1rem;
  width: 6rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #393939;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.4px;
  text-transform: uppercase;
}

.contractor-name {
  color: #0f0f0f;
  font-family: Poppins;
  padding-top: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 138.889% */
  text-transform: capitalize;
  font-size: 1rem;
}
.contractorid {
  font-size: 10px;
}
.contractor-count-group {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.user-card-heading {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.user-email-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 1rem;
}
.user-email-details-property,
.user-email-details-data {
  display: grid;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}
.user-email-details-property span,
.user-email-details-data span {
  padding-top: 8px;
}
.user-email-details-data {
  justify-content: end;
}
.contractor-grid-button {
  position: relative;
  top: 20.7px;
}
.option-container {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 0.375rem;
  background: #fff;
  position: absolute;
  right: 5px;
}

.option-container span:hover {
  background: #ccc;
  cursor: pointer;
}

.contractor-reset-password {
  display: flex;
}
.contractor-reset-password .contractor-name {
  flex: 1;
  position: relative;
  bottom: 1.5rem;
}

.bg-blue {
  background: #2b59c3;
  color: #fff;
}
.filter-button,
.filter-button:hover {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #e0e0e0;
}

.filter-popup-container {
  width: 25%;
  border-radius: 8px;
  border: 1px solid #99a7bf;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  padding: 1rem;
  z-index: 9;
  left: 18rem;
}
.filter-popup-container .filter-heading {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.filter-popup-container .filter-fields {
  display: flex;
  grid-gap: 10px;
  padding: 10px 0;
}

.Processing-btn {
  border-radius: 4px;
  background: rgba(31, 134, 255, 0.2);
  color: #1f86ff;
}
.Rejected-btn {
  border-radius: 4px;
  background: rgba(235, 28, 28, 0.2);
  color: #eb1c1c;
}
.Accepted-btn {
  border-radius: 4px;
  background: rgba(24, 183, 88, 0.2);
  color: #18b758;
}

.search-group {
  display: flex;
  margin-right: 10px;
  border-radius: 5px !important;
}
.search-group input {
  border: none;
  flex: 1;
}
.search-group svg {
  position: relative;
  top: 4px;
}

.custom-input {
  display: flex;
  align-items: center;
}

.custom-input input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  /* width: 200px; */
}

.custom-input svg {
  margin-left: 8px;
  cursor: pointer;
  margin-right: 5px;
}

.filter-popup-container .react-datepicker-wrapper {
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  border-radius: 0.5rem;
  color: #6e6e6e;
  margin: 10px 0;
}
.filter-popup-container .react-datepicker-wrapper input {
  flex: 1;
  border: none;
}

.off-canvas-menu {
  position: fixed;
  top: 0;
  right: -400px; /* Start with the menu hidden to the right */
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  color: #0000;
  transition: right 0.3s;
  z-index: 1000;
  overflow-y: auto;
}

.off-canvas-menu.open {
  right: 0; /* Slide in the menu when it's open */
}

/* .menu-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
} */

.card-footer .form-check-input:checked,
.update-promotion-container .form-check-input:checked {
  background-color: #2b59c3;
  border-color: #2b59c3;
}
.card-footer .form-check-input:checked,
.update-promotion-container .form-check-input:checked {
  background-color: #2b59c3 !important;
  border-color: #2b59c3 !important;
}
.admin-table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
.admin-table tr th,
.admin-table tr td {
  border-right: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 10px;
}
.admin-table tr th:first-child,
.admin-table tr td:first-child {
  border-left: 1px solid #bbb;
}
.admin-table tr th {
  /* background: #eee; */
  border-top: 1px solid #bbb;
  text-align: left;
}

.btn-dark {
  background: #0f0f0f;
  color: #fff;
}

.help-container {
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 5px;
  color: #000;
}
.help-container table {
  border: 1px solid;
  margin: 1rem;
  text-align: center;
}
.help-container table th {
  text-align: center;
  font-size: 18px;
  padding: 5px 10px;
}
.help-container table th td {
  text-align: center !important;
}
.help-container tr {
  border: 1px solid;
  font-size: 1rem;
}

.help-container tr td {
  border-right: 1px solid;
  font-size: 1rem;
  padding: 5px 10px;
  text-align: center;
}
/* top-left border-radius */
.admin-table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
.admin-table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
.admin-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
.admin-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.reward-redemption-reject,
.reward-redemption-reject:hover {
  background: #eb1c1c;
  color: #fff;
}
.reward-redemption-success,
.reward-redemption-success:hover {
  background: #18b758;
  color: #fff;
}

.admin-permisiion-offcanvas {
  width: 31.8rem !important;
  padding-bottom: 1rem !important;
}
.admin-permisiion-offcanvas .offcanvas-header {
  display: flex;
  justify-content: end;
}

.error-color {
  color: #eb1c1c;
}
.success-color {
  color: #18b758;
}
.disabled-row {
  background: #f3f3f3 !important;
}
.reject-modal-confirm {
  position: absolute;
  bottom: 1rem;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
  left: 4rem;
  width: 20rem;
}
.reject-modal-main {
  padding: 1rem;
}
.reject-modal-confirm .close {
  padding-top: 1rem;
  text-align: end;
  cursor: pointer;
}
.reject-modal-confirm .close svg {
  height: 15px;
}

.user-email-details-items {
  display: flex;
  color: #000;
  font-size: 13px;
  padding-top: 5px;
}
.user-email-details-items .detial-property {
  flex: 1;
}
.permission-view-area {
  color: #000;
  position: relative;
  top: 5px;
}
